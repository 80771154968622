<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <AnagraficaPersona
      v-if="isLoaded && !isError"
      :persona="persona"
      @refreshPersona="personaView"
    />
    <div class="mx-auto my-auto text-center" v-else-if="!isLoaded && !isError">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div class="mx-auto my-auto text-center" v-else>
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AnagraficaPersona from "@/views/persone/dettaglio-persona/AnagraficaPersona.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import services from "@/axios/dbManag";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "anagrafiche-insegnante",
  components: { AnagraficaPersona, Loading },
  // props: {
  //   persona: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  setup() {
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const isLoaded = ref(false);
    const isError = ref(false);

    const persona = ref({});

    const personaView = () => {
      isLoaded.value = false;
      services.dbManag
        .post(`/anagrafiche/persone/view`, {
          id: id_persona.value,
        })
        .then((res) => {
          persona.value = { ...res.data.results[0] };
          // read_only.value = res.data.read_only;
          isLoaded.value = true;
        })
        .catch((e) => {
          isError.value = true;
        });
    };
    personaView();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    return {
      isLoaded,
      isError,
      id_persona,
      personaView,
      persona,
    };
  },
});
</script>
